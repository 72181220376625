.slider-carousel{
  height: auto;
}

.home-carousel{
  z-index: 1;
  height: 35vh;
  margin-top: 20vh 
}


.container__image--product {
  width: 100%;
  height: 75vh;
  object-fit: cover;
}


.container__header--title-slogan{
  display: flex;
  align-items: center;
  color: #857251;
  font-size:1rem;
  margin-top: 0.5%;
  margin-bottom: 0.5%;
  margin-left: 5%;
  margin-right: 5%;
}


.container__span--texto-verde{
  color: #93C47D;
}


.container__image--client {
  width: 100%;
  height: 20vh;
  object-fit: contain;
  border: 1px solid #827153;
}

.container--client-carousel{
  max-width: 95%;
  text-align: center;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
}

.client-carousel{
  border-radius: 8px;
}



.client-carousel .container__card-client,
.client-carousel .container__image--client {
  border-radius: 8px ;
}


.client-carousel .container__image--client{
  width: 100%;
  height: 100%;
}

.client-carousel button{
  display: none;
}


.container--button-section{
  text-align: center;
  justify-content:center;
  padding: .5rem .5rem;
  display: flex;
  align-items: center;
  background-color: #827153;
  height:10vh ;
  border-radius: 8px;
  box-shadow: 0 5px 10px #827153;
}


.container--button-section a > button{
  text-decoration: none;
  color: #857251;
  border-radius: 8px;
  text-align: center;
  font-size: 0.8rem;
  width: 23vw;
  max-width: 300px;
  height: 6vh;
  max-height: 55px ;
  font-family: inherit;
  font-weight: bold;
  cursor:pointer;
}

.container--button-section a{
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.container--clientes{
  border-radius: 8px;
  box-shadow: 0 2px 10px;
  padding-bottom: 1.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 1.6rem;
  margin-bottom: -0.2rem;
}

.container__header--title-clientes{
  display: flex;
  justify-content:center;
  color: #857251;
  font-size: 1.5rem;
  margin-left: 5%;
  margin-right: 5%;
  padding-top: 1rem;
}



 .container__header--porque-elegirnos{
  display: flex;
  justify-content:center;
  color: #857251;
  font-size: 1.5rem;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  padding-top: 20px;
}


.container--porque-iconos{
  
  height: 15vh;
  border-radius: 8px;
  box-shadow: 0 2px 10px;
  padding-bottom: 3rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  text-align: center;


}

.container--icons-parent{
  margin-right: 1.5rem;
}


.container--icons {
  max-width: 30.33%;
  height: 10vh;
  display:inline-flex;
  color: #857251;
}


.container--icons:nth-child(4),
.container--icons:nth-child(5) {
display: none;
}


.container--icons > h3{
  
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .8rem;
  transition: all 0.3s ease-in-out;
}

.container--icons > svg{
  width:100%;
  transition: all 0.3s ease-in-out;
}

/*.Blog.*/



.container--left{
  margin-top: 1.7rem;
  margin-bottom: 1.6rem;
  box-shadow: 0 2px 10px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.container--left
.container--blog-inicio {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0.5rem;
    color: #827153;
    border-radius: 8px;
}

.container--left{
  border-radius: 8px;
}

.container--blog-inicio h2,
.container--blog-inicio a{
  font-size: 1.5rem;
  margin-top: 0.3rem;
  text-decoration: none;
  color: #827153;
}

 .container--blog-inicio{
  border-radius: 8px;
  box-shadow: 0 5px 10px #827153;
}

.card--acai-bowl-inicio{
  border: 3px solid #827153;
  border-radius: 8px;
}

.card--acai-bowl-inicio .image{
  background-image: url('../assets/assetslagranja/Blog/acai_bowl.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position:0px;
  max-width: 85%;
  padding: 20px;
  height:200px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  box-shadow: 0 5px 10px

}

.card--acai-bowl-inicio p{
  max-width: 90%;
  font-size: 1.3rem;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.button--blog{
  border-radius: 8px;
  text-align: center;
  font-size: 1.3rem;
  width: 42%;
  max-width: 200px;
  height: 5vh;
  font-family: inherit;
  margin-bottom: 1rem;
  color: #827153;
  box-shadow: 0 5px 5px;
  font-weight: bold;
  cursor: pointer
}

/*.Blog.*/

.container--icons:hover > svg,
.container--icons:hover > h3 {
color: #93C47D;
stroke: #93C47D;
}



@media screen and (min-width: 769px){


  .home-carousel{
    z-index: 1;
   /* height: 35vh;
    margin-top:57% ;*/
  }
  

  .container__header--title-slogan {
    display: flex;
    margin-left: 5%;
    margin-right: 5%;
    justify-content: center;
    font-size: 1.7rem;
    margin-top: 0;
    margin-bottom: 0;
  }

  .slider-carousel > h2{
    font-size: 2rem;
  }

  .container__header--porque-elegirnos{
    font-size: 2rem;
    padding-bottom: 2%;
  }

  .container__header--title-clientes{
    font-size: 2rem;

  }

  .container--blog-inicio h2{
    font-size: 2rem;

  }
  

  
  .container--icons > h3{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
  }


  .container--porque-iconos{
  
    height: 21vh;
  
  
  }

  .button--blog{

    width: 25%;
    max-width: 35%;

  }

  .container--button-section a{
    margin-left: 0.5%;
    margin-right: 0.5%;
  }


  .container--button-section a > button{

    font-size: 1.5rem;
  }


}

@media screen and (min-width: 900px){

  .home-carousel{
    height: 45vh;
  }

    .container--icons {
    max-width: 18%;
    height: 10vh;
    display:inline-flex;
    color: #857251;
    vertical-align: middle;
  }


  .container--porque-iconos div:nth-last-child(3) h3,
    .container--porque-iconos div:nth-last-child(5) h3
     {
    width: 200%;
  }

  
     .container--porque-iconos div:nth-last-child(5) h3{
      width: 90%;
      margin-right: 10%;
     }


 .container--porque-iconos div:nth-last-child(4) h3{
        width: 150%;
      
      }

  .container--porque-iconos div:nth-last-child(1) h3{
    width: 200%;
  }


  .container--icons-parent{
    margin-right: 0rem;
  }

  
  
  .container--icons:nth-child(4),
   .container--icons:nth-child(5) {
    display:inline-flex;
    fill: #857251;
  }

  .container--icons:nth-child(4) svg,
    .container--icons:nth-child(5) svg{
    font-size:1.3em;
     margin-top: 1em;

  
  
  }

  .container--icons:nth-child(4):hover,
  .container--icons:nth-child(5):hover{
    color: #93C47D;
   fill: #93C47D;
  }

  

  .container--button-section a > button{

    font-size: 1.5rem;
  }





}

@media screen and (min-width: 1200px){
  .home-carousel{
    height: 55vh;
  }

  .container__header--title-slogan {
    display: flex;
    margin-left: 5%;
    margin-right: 5%;
    justify-content: center;
  } 
  .slider-carousel > h2{
    font-size: 2rem;
  }

  .container--icons{
    max-width: 20%;
  }

  .container--icons > h3{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
  }
  .container--button-section > button{
    font-size: 1.5rem;
  }

  .button--blog{

    width: 15%;
    max-width: 25%;

  }

  .card--acai-bowl-inicio .image{

    max-width: 90%;

  
  }
  
  .card--acai-bowl-inicio p{
    max-width: 90%;

  }


  .container--icons:nth-child(4) svg,
   .container--icons:nth-child(5) svg{
    font-size:1.5rem;
    margin-top: 0.5em;

  }

  

}

@media screen and (min-width: 1400px){

  .container--icons > h3{
    font-size: 1.5rem;
  }

  .container--icons:nth-child(4) svg,
  .container--icons:nth-child(5) svg{
   font-size:2rem;
   margin-top: 0.5em;

 }



}