.productos {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Delius Swash Caps', cursive;
    color: #827153;
    margin-top: 20vh;
    margin-bottom: 2vh;
  }
  .productos .productos__header--title .productos__header--subtitle {
    font-family: 'Delius Swash Caps', cursive;
    font-size: 60px;
    font-size: 1.5rem;
  }
  
  .productos__container--product-list {
    width: 90vw;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .productos__header--subtitle{
    text-align: center;
  }
  
 
  
  .container--product-item{
    display: block;
    border-radius: 15px;
    width: 70vw;
    max-width: 75vw;
    min-width: 60vw;
    height: auto;
    padding: 15px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 5px;
    margin-bottom: 15px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  }

  .container--product-item:hover {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-in;
    cursor: pointer;
  }
  
  .container--product-item div {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 30vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .container--product-item h1 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    text-align: center;
    font-size: 1rem;
  }

  .container--product-item p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    text-align: center
  }

  .button--producto{
    display: block;
    border-radius: 8px;
    text-align: center;
    font-size: 1rem;
    width: 35%;
    max-width: 40%;
    height: 5vh;
    font-family: inherit;
    color: #827153;
    box-shadow: 0 5px 5px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
  }

  .link--producto{
    text-decoration: none;
    color: #827153;
    cursor: pointer;
  }


  @media screen and (min-width: 769px) {

    
    .productos__container--product-list {
      width: 90vw;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      flex-flow: row wrap;
      gap: 10px;
      margin-bottom: 40px;
    }


    .container--product-item{
      border-radius: 15px;
      width: 30vw;
      height: auto;
      padding: 15px;
      max-width: 25vw;
      min-width: 15vw;
      margin-top: 15px;
      margin-bottom: 15px;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    }

    .container--product-item div {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      width: 25vw;
      height: 20vh;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .button--producto{
      font-size: 1.2rem;
      width: 50%;
      max-width: 70%;

    }

    .container--product-item h1 {
      font-size: 1.5rem;
    }

  }

  
  @media screen and (min-width: 1279px) {
  
    .button--producto{
        width: 35%;
        max-height: 25%;
        max-width: 50%;
        font-size: 1.2rem;
      }

      .productos__container--product-list {

        flex-flow: row wrap;
      }
    }



