
  .header--blog {
    padding: 30px;
    font-size: 1.5rem;
    text-align: center;
    color: #827153;
    text-decoration: underline;
  }

  .header--blog .header--Beneficios{
    font-size: 1.5rem ;
    animation-duration: 1.5s;
    animation-name: Title-appear;
}

@keyframes Title-appear {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}


  .main--blog, .leftcolumn--blog-post, .rightcolumn, .card {
    color: #827153;
  }

  .row{
    display: flex;
  }
  
  .leftcolumn--blog-post {   
    border-radius: 8px;

  }

.card--beneficios-acai{
  border: 3px solid #827153;
  border-radius: 8px;
  box-shadow: 0 10px;
  padding: 15px;
}

.card--beneficios-acai .img-blog-post{
background-image: url('../assets/assetslagranja/Blog/acai_berries.jpg');
background-size: cover;
background-position: 2px;
background-repeat: no-repeat;
height: 50vh;
border-radius: 8px;
}


  

  .rightcolumn {
    float: left;
    width: 25%;


  }
  

  .card--sobre-nosotros .image{
    background-image: url('../assets/assetslagranja/Logo_Pulpas-removebg-preview.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position:2px;
    width: 95%;
    padding: 20px;
    height:200px;
    
  }




  .card--sobre-nosotros{
    box-shadow: 0 5px 15px;
    border-radius: 8px;
    padding: 15px;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  .card--sobre-nosotros a{
    text-decoration: none;
    color: #827153
  }

  
  .card--productos-populares{
    box-shadow: 0 5px 15px;
    border-radius: 8px;
    padding: 15px;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  .card--productos-populares a{
    text-decoration: none;
    color: #827153
  }



  .fakeimg {
    background-color: #aaa;
    width: 100%;
    padding: 20px;
    height:200px;
  }
  

  .card {
     background-color: white;
     padding: 20px;
     margin-top: 20px;
  }
  

  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  

.card--productos-populares svg{
  height: 19px;
  width: 19px;
}
  
 
  @media screen and (min-width: 800px) {

    .leftcolumn--blog-post {   
      float: left;
      width: 75%;
    }
    .card--beneficios-acai p{
      padding: 30px;
    }
    .card--sobre-nosotros{
      margin-bottom: unset;
    }

    .card--productos-populares{
      margin-top: unset;
      margin-bottom: unset;
    }

  }


  
  @media screen and (min-width: 900px) {

    .header--blog .header--Beneficios{
      font-size: 1.8rem ;
  
  }
  }

  @media screen and (min-width: 1400px) {

    .header--blog .header--Beneficios{
      font-size: 2rem ;

  }
  }