

  .main--blog{
    margin-top: 35%;
  }

.main--blog a{
  text-decoration: none;
  color: #827153;
}

  .header--blog {
    padding: 30px;
    text-align: center;
    color: #827153;
    text-decoration: underline;
  }

  .header--blog h1{
    font-size: 1.5rem;
    animation-duration: 1.5s;
    animation-name: Title-appear;

}

@keyframes Title-appear {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}


  .main--blog, .leftcolumn, .rightcolumn, .card {
    color: #827153;
    font-size: 1.4rem;
  }

  .row{
      display: flex;
      flex-direction: column;
      max-width: 95%;
      align-self: center;
      margin-left: auto;
      margin-right: auto;
    }
  
  
  .leftcolumn {   
    float: left;
    width: 75%;
    border-radius: 8px;
  }

  .leftcolumn h1,
  .leftcolumn a{
    text-decoration: none;
    color: #827153;
  }

  .leftcolumn h2{
    font-size: 1.2rem;
  }

.card--postre-maracuya{
  border: 3px solid #827153;
  border-radius: 8px;
  box-shadow: 0 10px;
  padding:1rem
}

.card--postre-maracuya .image{
  background-image: url('../assets/assetslagranja/Blog/cake-g7c5b8d4a4_1280.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;
  padding: 20px;
  height:200px;
  border-radius: 8px;

}

.card--acai-bowl{
  border: 3px solid #827153;
  border-radius: 8px;
  box-shadow: 0 10px;
  padding:1rem
}


.card--acai-bowl .image{
  background-image: url('../assets/assetslagranja/Blog/acai_bowl.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position:2px;
  max-width: 100%;
  padding: 20px;
  height:200px;
  border-radius: 8px;

}

.card--acai-berrie{
  border: 3px solid #827153;
  border-radius: 8px;
  box-shadow: 0 10px;
  padding:1rem;
  margin-bottom:10px
}


.card--acai-berrie .image{
  background-image: url('../assets/assetslagranja/Blog/acai_berries.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position:2px;
  max-width: 100%;
  padding: 20px;
  height:200px;
  border-radius: 8px;
}

  

  .rightcolumn {
    /*float: left;*/
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: 25%;
    padding-left: 20px;

  }
  

  .card--sobre-nosotros .image{
    background-image: url('../assets/assetslagranja/Logo_Pulpas-removebg-preview.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position:2px;
    width: 95%;
    padding: 20px;
    height:200px;
      
  } 


  .image--acai{
    background-image: url('../assets/assetslagranja/Blog/Lagranja-pulpa-tamarindo-ladespensa-medellin-1-300x300.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 70%;
    padding: 20px;
    height:300px;
    box-shadow: 0 10px 20px;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
  }

  .image--maracuya{
    background-image: url('../assets/assetslagranja/Blog/Lagranja-maracuya-3.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 70%;
    padding: 20px;
    height:300px;
    box-shadow: 0 10px 20px;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    
  }

  
  .card--sobre-nosotros{
    display: block;
    margin-right: auto;
    margin-left: 1rem;
    box-shadow: 0 5px 15px;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
  }

  

  .card--productos-populares{
    display: block;
    margin-right: auto;
    margin-left: 1rem;
    box-shadow: 0 5px 15px;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 1rem;
 
  }


  .fakeimg {
    background-color: #aaa;
    width: 100%;
    padding: 20px;
    height:200px;
  }
  

  .card {
     background-color: white;
     padding: 20px;
     margin-top: 20px;
  }
  

  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  @media screen and (min-width: 530px) {
   
    .main--blog{
      margin-top: 25%;
    }
  

  }

  @media screen and (min-width: 769px) {
       
    .main--blog{
      margin-top: 20%;
    }
  
    .row{
      display: flex;
      flex-direction: row;
      max-width: 100%;
    }
    .rightcolumn {
      /*float: left;*/
      display: block;
      max-width: 50%;
      margin-top: -1.5rem;
      padding-left: unset;
    }

    .card--sobre-nosotros{
      margin-bottom: 2rem;
    } 

    .main--blog .card--sobre-nosotros .image{
      background-image: url('../assets/assetslagranja/Logo_Pulpas-removebg-preview.png');
      background-repeat: no-repeat;
      background-size:cover;
      max-width: 50%;
      padding-right: 50px;
      margin-right: 60px;
      height:70px;
      
    }

    @media screen and (min-width: 900px) {
       
      .main--blog{
        margin-top: 15%;
      }
      .main--blog .card--sobre-nosotros .image{
        max-width: 60%;
        margin-right: 10px;
        height:100px;
        
      }

      .main--blog .card--productos-populares div{
        background-position: -2%;
      }

      .header--blog h1{
        font-size: 2rem;
        animation-duration: 1.5s;
        animation-name: Title-appear;
    
    }

    .leftcolumn h2{
      font-size: 1.7rem;
    }
  
    
    

    }

  }

    @media screen and (min-width: 1200px) {
       
      .main--blog{
        margin-top: 10%;
      }
    

    }

