
.location {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Delius Swash Caps', cursive;
  margin-top: 20%;
}
  
.container--Banner-page-E {
  width: 100%;
  height: 28vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Delius Swash Caps', cursive;
  opacity: 0.6
}

.container--centered{
  position: absolute;
  width: 70vw;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-decoration-line: underline;
  text-decoration-color: rgb(156, 135, 15);
}

.container--centered h1{
  font-size: 2rem;
  text-align: center;
}

.location p{
  font-size: 1.2rem;
  text-align: center;
  width: 50vw;
  margin-top: 1%;
  margin-bottom: 1%;
  color:#827153;
}

.location p:first-of-type{
  margin-bottom: 0;
}

.container--mapa{
  margin-bottom: 15px;
  height: 30vh;
  width: 30vh;
  margin-left: auto;
  margin-right: auto;
  min-width: 220px;
  min-height: 180px;
  max-width: 700px;
  max-height: 700px;
}

.container--mapa iframe{
  margin-top: unset;
  height: 30vh;
  width: 30vh;
  min-width: 220px;
  min-height: 180px;
  max-width: 700px;
  max-height: 700px;
}





  @media screen and (min-width: 769px) {

    .location {
      margin-top: 10%;
    }
    
    .container--centered h2{
      font-size: 4rem;
      text-align: center;
    }

    .container--mapa{
      margin-bottom: 30px;
      width: 60vw;
      height: 60vh;
      max-width: 800px;
      max-height: 800px;
      text-align: center;
    }

      
    .container--mapa > iframe{
      width: 100%;
      height: 100%;
     }


  }

  @media screen and (min-width: 1024px) {

    .location {
      margin-top: 5%;
    }

  }

  
  @media screen and (min-width: 1200px) {

    .location p{
      font-size: 1.7rem;
    }

  }