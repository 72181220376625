.container--form-sent{
    display: flex;
    flex-direction: column;
    height: auto;
    width: 80vw;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    background-color:  #827153;
    color: white;
    border-radius: 8px;
    margin-bottom:20px;
    padding: 15px;
}



.container--message{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
    

.container--form-sent svg{

fill: white;
font-size: 50px;

}

.container--form-sent h2{
    font-size: 2rem;   
    vertical-align: middle; 
}

.container--form-sent p{
    font-size: 1.5rem;
}


@media screen and (min-width: 500px) {

    .container--form-sent{
        max-width: 450px;
    }
    


    .container--form-sent h2{
        font-size: 2.5rem;   
    }
    
    .container--form-sent p{
        font-size: 1.8rem;
    }
    
    .container--form-sent svg{
    
        font-size: 80px
     }

    

    

}


@media screen and (min-width: 769px) {


    .container--form-sent{

        margin-bottom:35px;
    }
    

}