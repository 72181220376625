
.contact {
  width: 100%;
  height: auto;
  display: block;
  font-family: 'Delius Swash Caps', cursive;
  margin-top: 20%;
}

.container--BannerPage-C {
  width: 100%;
  height: 28vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: inherit;
  opacity: 0.6
}


.container--centered{
  position: absolute;
  width: 70vw;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-decoration-line: underline;
  text-decoration-color:rgb(156, 135, 15);
}

.container--centered h1{
  font-size: 2rem;
  text-align: center;
  font-family: inherit;
}


.container--contacto{
  display: block;
  height: auto;
}

.container--GForm{
  margin-top: 2rem;
  margin-bottom: auto;
  width:100%;
  height: auto;
  text-align: center;
}

.container--GForm iframe{
  height: 100%;
  width: 90vw;
  margin-left: 10px;
  margin-right: 10px;
  border: 6px solid #827153;
  border-radius: 8px;
}



/*******************/
select,
textarea,
input[type=text],
input[type=email],
input[type=number]   {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type=submit]{
  background-color: #FEFEFE;
  color: #827153;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 150px;
  font-size: 1.2rem;
  font-family: inherit;
  font-weight: bold;
}


.container {
  border-radius: 5px;
  background-color: #827153;
  padding: 20px;
  width:80%;
  margin-left: auto;
  margin-right: auto;
  font-family: inherit;
  margin-bottom: 30px;
}

.container label, .container h3, .container h2{
  color: white;
  font-family: inherit;
}



/*******************/

.container--info-contacto{
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 5px;
}

.container--left-contact{
  display: flex;
  width: 50vw;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.container--left-contact > svg{
  width: 10vw;
  height: 10vh;
  display: block;
  margin: auto;
  fill: #827153;
  animation: shake-icons 0.3s 100
}


@keyframes shake-icons {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

.container--texto-contacto{ 
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #827153;
  margin-bottom: 2.5rem;
}

.container--texto-contacto > p{
  font-size: 1.5rem;
}

.container--texto-contacto a{
  text-decoration: none;
  font-size: 1.5rem;
  color: #827153
}


.bi_bi_whatsapp{
  position:fixed;
  width:65px;
  height:65px;
  bottom:85px;
  right:25px;
  z-index: 1;
  background:#25d366;
  color:#FFF;
  stroke:white;
  stroke-width: 0.29;
  border-radius:50px;
}


@media screen and (min-width: 769px) {

    .contact {
      margin-top: 10%;
    }
    

  .container--contacto{
    display: flex;
    flex-direction: row;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .container--info-contacto{
    display: block;
    width: 100%;
    height: auto;
    margin-top: 10rem;
    margin-bottom: auto;
    margin-left: 5px;
    margin-right: 5px;
  }

  .container--GForm{
    margin-left: 25px;
    margin-top: 2rem;
    margin-bottom: auto;
    width:100%;
    height: auto;
    text-align: center;

  }

  .container--GForm iframe{

    width:90%;
    height: 100%;
  }

  .container--left-contact{
    display: flex;
    width: 25vw;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  .bi_bi_whatsapp{
    box-shadow: 0px 1px 10px rgba(0,0,0,0.3);
  }

  .container--texto-contacto{ 
    margin-bottom: 0rem;
  }


}

@media screen and (min-width: 1024px) {

  .contact {
    margin-top: 5%;
  }

  .container--contacto{

    max-width: 80%;

  }


}

@media screen and (min-width: 1200px) {


  .container--contacto{

    max-width: 60%;

  }


}
