

.Pedidos {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Delius Swash Caps', cursive;
  margin-top: 15vh;
}

.container--Banner-page-pedidos{
  width: 95%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.9;
  padding-left: 5%;

}

.container--pedidos{
  width: 90%;
  height: 55vh;
  margin-right:auto;
  margin-top: 150px;

}


.container--logo-pedidos {
  width: 45vw;
  height: 20vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
}


#botones-pedido{
  background-color: transparent;
  display: block;
  padding: 0;
  padding-top: 1px;
  align-items: center;
  width: 300px;
  /*
  height: 15vh;
  */
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.2rem;
  
}

#botones-pedido{
  height: auto;
  padding: 5px;
}

/*

#botones-pedido{
  height: 170px;
}

*/

#botones-pedido a{

  text-decoration: none;

}



#botones-pedido a > button{

  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 250px;
  font-weight: bold;
  font-size: 1.2rem;
}

@media screen and (min-width: 600px){
  .container--logo-pedidos {
    width: 36vw;
    height: 28vh;
 

  }

}
 

@media screen and (min-width: 769px){

  .Pedidos {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: 'Delius Swash Caps', cursive;
  }
  
  .container--Banner-page-pedidos{
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.9;
    padding-left: 5%;
  
  }
  
  .container--pedidos{
  
    width: 90%;
    height: 55vh;
    margin-right:auto;
    margin-right:auto;
    margin-top: 80px;
  
  }
  
  
  .container--logo-pedidos {
    width: 30vw;
    height: 35vh;
  }
/*
  #botones-pedido{
    height: 200px;
  }

*/
}

@media screen and (min-width: 1024px){
  .container--logo-pedidos {
    width: 22vw;
    height: 35vh;
  }

}

