


  @import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Delius&family=Delius+Swash+Caps&display=swap');


.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: 'Delius Swash Caps', cursive;
}

body {
  padding: 0;
  margin: 0;
}


