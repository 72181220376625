.about {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: 'Delius Swash Caps', cursive;
  margin-top: 20%;
}

.container--Banner-page-SN {
  width: 100%;
  height: 28vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Delius Swash Caps', cursive;
  opacity: 0.6;
}


.container--centered-SN {
  position: absolute;
  width: 70vw;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-decoration-line: underline;
  text-decoration-color: rgb(156, 135, 15);

}

.container--centered-SN h1 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;
}


.container--aboutBottom {
  display: block;
  /* flex-direction: column;*/
  width: 90%;
  height: 86%;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  padding: 15px;
  color: #827153;
}

.container--aboutBottom h3 {
  font-size: 1.2rem;
  text-align: center;
}

.container--aboutBottom h4 {
  font-size: 1rem;
}

.container__header--italic {
  font-style: italic;
}


/*Table*/

table tr {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

table td {
  width: 100%;
  height: 50%;
}

table .first-row__first-cell,
table .second-row__second-cell{
  height: auto;
}

table .table__second-row {
  flex-direction: column-reverse;
}


.article--expertos {
  margin-top: 3%;
}


.container--expertos,
.container--atributos {
  height: 90%;
  width: 95%;
  text-align: justify;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0rem;
  margin-bottom: 0rem;
  padding:10px
}

.container--atributos{
margin-top: 0.5rem;
}


.container--expertos-img,
.container--atributos-img {

  min-height: 150px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
}


.container--expertos-img {
  background-image: url('../assets/assetslagranja/mango-1239347_640.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;

}

.container--atributos-img {
  background-image: url('../assets/assetslagranja/pexels-kindel-media-8181545.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  background-position: 2%;
}


.container--aboutBottom h4 {
  margin-top: auto;
  margin-bottom: auto;
}


.about .container--aboutBottom p .container--aboutBottom p {
  font-size: 1rem;
  color: black;
}





@media screen and (min-width: 769px) {
  .about {
    margin-top: 10%;
  }

  .container--expertos,
  .container--atributos {
    width: 95%;
    padding: 2px;

  }


  .container--atributos-img {
    width: 95%;
    height: 90%;
  }

  .container--expertos-img{
    width: 95%;
    height: 90%;
  }

  .container--atributos-img{
    margin-top: 0.5rem;
  }

  table td {
    width: 50%;
    display: flex;
    height: auto;
    vertical-align: middle;
  }

  table tr {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
  }

  table .table__second-row {
    flex-direction: row;
  }

  .container--centered-SN h1 {

    margin-bottom:1.2rem;
  }

}


@media screen and (min-width: 1024px) {

  .about {
    margin-top: 5%;
  }

  .container--aboutBottom h3 {
    font-size: 1.2rem;
  }

  .container--aboutBottom h4 {
    font-size: 1.1rem;
  }

  .container--aboutBottom p {
    font-size: 1rem;
  }



  @media screen and (min-width: 1200px) {

    .container--aboutBottom h3 {
      font-size: 1.3rem;
    }

    .container--aboutBottom h4 {
      font-size: 1.1rem;
    }

    .container--aboutBottom p {
      font-size: 1rem;
    }

  }

  @media screen and (min-width: 1400px) {
  .container--expertos-img{
    width: 95%;
    height: 98%;
    margin-bottom: 1rem;
  }


}

}