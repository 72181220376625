.footer {
  width: 100%;
  height: auto;
  background-color: #F7F4EE;
  display: inline-flex;
  box-shadow: 0 0.5px 5px;  
}


.footer__container--left-side{
  display: block ;
  width: 33.33%;
  height: auto;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}


.footer__container--left-side svg {
  color: Gray;
  margin-top: 2rem;
  font-size: 2rem;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.container__p--sociedad {
  margin-bottom: 2%;
  font-size: 0.7rem;
  margin-left: auto;
  margin-right: auto;
}

 .container__p--horarios{

  margin-bottom: 15%;
  font-size: 0.7rem;
  margin-left: auto;
  margin-right: auto;
 }

 .footer__container--center{
  display: block ;
  width: 33.33%;
  height: auto;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
} 

.container__p--copyright{
  font-size: 0.7rem;
}


 .footer__container--right-side{
  display: block ;
  width: 33.33%;
  height: auto;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
} 

.footer__container--right-side a{
  text-decoration: none;
  color:#000000;
}

.container__p--direccion{

  font-size: 0.7rem;
}

.container__p--ciudad{

    font-size: 0.7rem;
}



.container__p--sociedad,
 .container__p--horarios,
  .container__p--direccion,
   .container__p--ciudad{
  width:90%
}


@media screen and (min-width: 769px) {


  .footer__container--right-side p,
  .footer__container--left-side p,
  .footer__container--center p{

      font-size: 0.8rem;
  }

  .footer__container--left-side svg {
    font-size: 2rem;
  }

}

@media screen and (min-width: 1024px) {
  .footer__container--left-side svg {
    margin-top: 1.5rem;
  }

  .footer__container--left-side svg {
    font-size: 3rem;
  }

}

@media screen and (min-width: 1280px) {

  .footer__container--left-side svg {
    font-size: 4rem;
  }

  .footer__container--right-side p,
  .footer__container--left-side p,
  .footer__container--center p{

      font-size: 1.1rem;
  }


}