.navbar {
  width: 100%;
  height: 20vh;
  background-color: #F7F4EE;
  display: flex;
  box-shadow: 0 0.5px 5px;
  position: fixed;
  z-index: 10;
}

.container--left-side {
  flex: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 1rem;
}

.container--left-side {
  font-size: large;
}

.container--left-side img,
.container--left-side .link--home {
  width: 75%;
  height: auto;
}

.container--right-side{
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar a {
  color: #6A6A6A;
  text-decoration: none;
  margin: 20px;
}

.container--right-side a {
 display: none;
}


.container--right-side button {
  background-color: transparent;
  border: none;
  color: #827153;
  cursor: pointer;
  margin-left: 0.5rem;
}

.container--right-side svg {
  font-size: 40px;
}



.navbar #open {
  padding-left: 0px;
}
.navbar #open img {
  display: none;
}

.navbar #close img {
  display: inherit;
}

.navbar #open .container--hidden-links {
  width: 100%;
  height: 8vh;
  margin-right: .05rem;
  margin-left: 0.5rem;
}

.navbar #close .container--hidden-links {
  display: none;
}

.navbar #open a {
  width: 65px;
  margin: 3px;
  font-size: 0.9rem;
  font-weight: bold;
}




@media screen and (min-width: 769px) {

  .container--right-side > button {
    display: none; 
  }

  .navbar #open .container--hidden-links  {
    display: none; 
  }

  .navbar #open img  {
    display:inherit; 
  }

  .container--left-side img,
  .container--left-side .link--home {
    width: 70%;
    height: auto;
  }
  
  .container--right-side{
    display: flex;
    background-color: inherit;
    width: auto;
    text-align: right;
  }
  
  .container--right-side a{
    display: flex;
    flex-direction: row;
    background-color: inherit;
    color: #6A6A6A;
    font-weight: bolder;
    margin: 6px;
  }

  #link--SN{
    width: 92px;
    margin-left: 5px;
    margin-right: 1px;
  }

}

@media screen and (min-width: 1279px) {

  .container--left-side img,
  .container--left-side .link--home{
    width: 52%;
    height: auto;
  }
  .container--right-side{
    display: flex;
    background-color: inherit;
    width: auto;
    text-align: right;
  }
  
  .container--right-side a{
    font-size: 0.8rem;
  }

}

@media screen and (min-width: 1400px) {
  
  #link--SN{
    width: 127px;
    margin-left: 6px;
    margin-right: 1px;
  }

  .container--right-side a{
    font-size: 1.1rem;
  }


}


